<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],

      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      safeinfo: [],
      loading: true,
      goodslist: [],
      cashierconfig: [],
      syncgoodslist: []
    };
  },
  mounted() {
    this.title = this.$t("menuitems.goods.text");
    this.items = [
      {
        text: this.$t("menuitems.goods.text"),
        active: true,
      },
    ];

    this.userinfo = JSON.parse(localStorage.getItem("user"));
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    this.getgoodslist();
    this.getcashierconfig();
  },
  methods: {
    getgoodslist() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "goodslist",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.goodslist = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deletegoods(id) {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "deletegoods",
            id: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
            that.getgoodslist();
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
          console.log(this.$t("menuitems.devices.printer"));
        });
    },
    getcashierconfig() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "get_cashier_config",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.cashierconfig = response.data.config
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    syncgoods() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "get_" + that.cashierconfig.type + "_goodslist",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          // that.syncgoodslist = response.data.data.foodList
          that.$message({
            message: response.data.msg,
            type: "success",
          });
          that.getgoodslist()
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <div class="col-sm-12 col-md-6">
              <p>
                <router-link :to="{ path: '/goods/add' }">
                  <a href="javascaript:;" class="btn btn-success">
                    <i class="ri-file-add-line"></i>
                    {{ $t("goods.add") }}
                  </a>
                </router-link>
                <el-button v-if="cashierconfig.status && cashierconfig.type == 'hualala'" type="primary"
                  @click="syncgoods">同步商品</el-button>
              </p>
            </div>
            <table id="deviceslist_table" class="table table-striped">
              <thead>
                <tr>
                  <th class="sortStyle">ID</th>
                  <th class="sortStyle">{{ $t("goods.lists.title") }}</th>
                  <th class="sortStyle">{{ $t("goods.lists.cover") }}</th>
                  <th class="sortStyle">{{ $t("goods.lists.description") }}</th>
                  <th class="sortStyle">{{ $t("device.lists.action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, idx) in goodslist" :key="idx">
                  <td>{{ val.id }}</td>
                  <td>{{ val.title }}</td>
                  <td>
                    <el-image style="width: 40px; height: 40px" :src="val.coverimg" :preview-src-list="[val.coverimg]">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </td>
                  <td>{{ val.description }}</td>
                  <td>
                    <router-link :to="{
                      path: '/goods/add',
                      query: { id: val.id },
                    }">
                      <el-button type="primary" round>{{
                        $t("device.lists.edit")
                      }}</el-button>
                    </router-link>
                    <!-- <router-link :to="{
                          path: '/goods/bind',
                          query: { id: val.id },
                        }">
                      <el-button type="success" round>{{
                        $t("goods.lists.bind")
                      }}</el-button>
                    </router-link> -->

                    <el-popconfirm @confirm="deletegoods(val.id)" :title="$t('goods.lists.deletetips')">
                      <el-button slot="reference" class="rounded-pill" type="danger">{{ $t("device.lists.delete")
                      }}</el-button>
                    </el-popconfirm>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>